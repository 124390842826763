import React, { useContext } from 'react';
import cx from 'classnames';
import { Link, graphql } from 'gatsby';
import DefaultLayout from '../layouts';
import {
  CartContext,
  useGoToCheckout,
  useUpdateItemInCart,
  useRemoveItemFromCart,
} from '../contexts/CartContext';
import CartItem from '../components/CartItem/CartItem';

import styles from '../components/CartItem/styles.module.scss';

const Cart = ({ data }) => {
  const {
    cart: {
      checkout: { lineItems, totalPrice },
    },
    cart,
  } = useContext(CartContext);
  const goToCheckout = useGoToCheckout();

  const updateItem = useUpdateItemInCart();
  const removeItem = useRemoveItemFromCart();

  // pass some extra attributes to CartItem, for linking back to product
  const getExtraAttributes = id => {
    const vendor = data.allShopifyProduct.edges.reduce(
      (
        result,
        { node: { hasOutOfStockVariants, handle, vendor, variants } }
      ) => {
        const match = variants.find(variant => variant.storefrontId === id);

        if (match) {
          result.handle = handle;
          result.vendor = vendor;
          result.hasOutOfStockVariants = hasOutOfStockVariants;
          result.variantCount = variants.length;
        }
        return result;
      },
      {}
    );
    return vendor;
  };

  const cartItems = lineItems.map(lineItem => {
    return {
      ...lineItem,
      ...getExtraAttributes(lineItem.variant.id),
    };
  });

  return (
    <DefaultLayout>
      <div className="base">
        <div className={cx('container', styles.base)}>
          <h1 className="h3">Shopping cart</h1>
          {lineItems.length ? (
            <>
              <div className={styles.cart}>
                <ul>
                  {cartItems.map(item => (
                    <CartItem
                      key={item.id}
                      item={item}
                      remove={removeItem}
                      update={updateItem}
                    />
                  ))}
                </ul>
                <div className={styles.total}>
                  <span>Total</span>&nbsp;&nbsp;
                  <span>${totalPrice}</span>
                </div>
              </div>
              <div className="d-flex d-flex-end">
                <Link
                  className="btn btn--secondary space-right"
                  to="/subscribe"
                >
                  Continue shopping
                </Link>
                <button className="btn" onClick={goToCheckout}>
                  Go To Checkout
                </button>
              </div>
            </>
          ) : (
            <div>
              <div className={styles.emptyCart}>
                <p>Your cart is currently empty.</p>
              </div>

              <Link to="/subscribe" className="btn">
                Add a subscription
              </Link>
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  {
    allShopifyProduct {
      edges {
        node {
          shopifyId
          storefrontId
          vendor
          title
          handle
          hasOutOfStockVariants
          variants {
            shopifyId
            storefrontId
          }
        }
      }
    }
  }
`;

export default Cart;
